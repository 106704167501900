// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-acceptable-use-policy-tsx": () => import("./../../../src/pages/acceptable-use-policy.tsx" /* webpackChunkName: "component---src-pages-acceptable-use-policy-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-developers-image-speed-test-[id]-tsx": () => import("./../../../src/pages/developers/image-speed-test/[id].tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-[id]-tsx" */),
  "component---src-pages-developers-image-speed-test-index-tsx": () => import("./../../../src/pages/developers/image-speed-test/index.tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-index-tsx" */),
  "component---src-pages-developers-image-speed-test-partners-generic-[id]-tsx": () => import("./../../../src/pages/developers/image-speed-test/partners-generic/[id].tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-partners-generic-[id]-tsx" */),
  "component---src-pages-developers-image-speed-test-partners-generic-index-tsx": () => import("./../../../src/pages/developers/image-speed-test/partners-generic/index.tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-partners-generic-index-tsx" */),
  "component---src-pages-developers-image-speed-test-temp-[id]-tsx": () => import("./../../../src/pages/developers/image-speed-test/temp/[id].tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-temp-[id]-tsx" */),
  "component---src-pages-developers-image-speed-test-temp-index-tsx": () => import("./../../../src/pages/developers/image-speed-test/temp/index.tsx" /* webpackChunkName: "component---src-pages-developers-image-speed-test-temp-index-tsx" */),
  "component---src-pages-developers-index-tsx": () => import("./../../../src/pages/developers/index.tsx" /* webpackChunkName: "component---src-pages-developers-index-tsx" */),
  "component---src-pages-developers-integration-guides-tsx": () => import("./../../../src/pages/developers/integration-guides.tsx" /* webpackChunkName: "component---src-pages-developers-integration-guides-tsx" */),
  "component---src-pages-features-easy-to-use-tsx": () => import("./../../../src/pages/features/easy-to-use.tsx" /* webpackChunkName: "component---src-pages-features-easy-to-use-tsx" */),
  "component---src-pages-features-global-content-delivery-tsx": () => import("./../../../src/pages/features/global-content-delivery.tsx" /* webpackChunkName: "component---src-pages-features-global-content-delivery-tsx" */),
  "component---src-pages-features-how-we-compare-tsx": () => import("./../../../src/pages/features/how-we-compare.tsx" /* webpackChunkName: "component---src-pages-features-how-we-compare-tsx" */),
  "component---src-pages-features-image-optimization-tsx": () => import("./../../../src/pages/features/image-optimization.tsx" /* webpackChunkName: "component---src-pages-features-image-optimization-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-mobile-device-detection-tsx": () => import("./../../../src/pages/features/mobile-device-detection.tsx" /* webpackChunkName: "component---src-pages-features-mobile-device-detection-tsx" */),
  "component---src-pages-features-next-gen-formats-tsx": () => import("./../../../src/pages/features/next-gen-formats.tsx" /* webpackChunkName: "component---src-pages-features-next-gen-formats-tsx" */),
  "component---src-pages-features-support-tsx": () => import("./../../../src/pages/features/support.tsx" /* webpackChunkName: "component---src-pages-features-support-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-contact-tsx": () => import("./../../../src/pages/partners-contact.tsx" /* webpackChunkName: "component---src-pages-partners-contact-tsx" */),
  "component---src-pages-partners-list-tsx": () => import("./../../../src/pages/partners-list.tsx" /* webpackChunkName: "component---src-pages-partners-list-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-payment-and-service-commitments-tsx": () => import("./../../../src/pages/payment-and-service-commitments.tsx" /* webpackChunkName: "component---src-pages-payment-and-service-commitments-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reseller-partner-marketing-requirements-tsx": () => import("./../../../src/pages/reseller-partner-marketing-requirements.tsx" /* webpackChunkName: "component---src-pages-reseller-partner-marketing-requirements-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-faq-w-demo-tsx": () => import("./../../../src/templates/faq-w-demo.tsx" /* webpackChunkName: "component---src-templates-faq-w-demo-tsx" */),
  "component---src-templates-image-text-page-tsx": () => import("./../../../src/templates/image-text-page.tsx" /* webpackChunkName: "component---src-templates-image-text-page-tsx" */),
  "component---src-templates-table-page-tsx": () => import("./../../../src/templates/table-page.tsx" /* webpackChunkName: "component---src-templates-table-page-tsx" */)
}

